import React from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

const SkillsCard = (props) => {
    const { name, image, imagealt } = props.data;
    const iconRender = () => {
        if (image == "") {
            return (
                <img
                    alt={"logo"}
                    src={imagealt}
                    height={"20px"}
                    className="mr-2"
                />
            );
        } else {
            return <i className={` mr-2 ${image}`} fontSize={"20px"}></i>;
        }
    };

    return (
        <Col md={3}>
            <Card className="card shadow-lg p-3 mb-5 bg-white rounded">
                <Card.Body>
                    <Card.Title className="mb-0">
                        {iconRender()} {name}
                    </Card.Title>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default SkillsCard;
