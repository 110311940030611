import React from "react";

const GetInTouch = (props) => {
    const { heading, message, email } = props.data;
    return (
        <div id="Get-In-Touch" className="container container-fluid">
            <h2 className="display-4 mb-5 text-center">{heading}</h2>
            <p className="lead text-center pb-3">
                {message}{" "}
                <a
                    href="#"
                    className="email cryptedmail"
                    data-name="hi"
                    data-domain="anshgoyal"
                    data-tld="com"
                    onClick={() =>
                        (window.location.href = "mailto:hi@anshgoyal.com")
                    }
                ></a>
                .
            </p>
        </div>
    );
};

export default GetInTouch;
