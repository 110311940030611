const mainBody = {
    message: " Passionate about changing the world with technology. ",
    icons: [
        {
            image: "fab fa-github",
            url: "https://github.com/anshg1214/",
        },
        {
            image: "fab fa-linkedin",
            url: "https://linkedin.com/in/anshgoyal31/",
        },
        {
            image: "fab fa-twitter",
            url: "https://twitter.com/anshgoyal31/",
        },
        {
            image: "fab fa-telegram",
            url: "https://t.me/anshgoyal/",
        },
        // {
        //     image: "fas fa-envelope-square",
        //     url: "mailto('hi@anshgoyal.com')",
        // },
    ],
};

const about = {
    show: true,
    heading: "About Me",
    imageLink: require("../information/profilepic.webp"),
    imageSize: 350,
    message:
        "I am a first year undergrad majoring in Computer Science and Engineering at BITS Pilani. My recent interests include full-stack development. I am very passionate about programming and engineering as a whole. I am looking for working on Open Source projects.",
    buttonText: "Resume",
};

const projectData = {
    show: true,
    heading: "Projects",
    projects: [
        {
            id: 1,
            title: "Taapmaan",
            description:
                "Taapmaan is a web app that fetches the weather data for the next 5 days from the <b>OpenWeatherMap API</b>. Location autocomplete when the user searches for their city, using the <b>OpenStreetMap API</b>. It also displays the current weather data for the user's location using <b>FreeGeoIp API</b>.",
            languages: [
                "NodeJS",
                "OpenWeatherMap API",
                "OpenStreetmap API",
                "FreeGeoIp API",
            ],
            liveLink: "https://taapmaan.anshgoyal.com",
            githubLink: "https://github.com/anshg1214/taapmaan",
        },
        {
            id: 2,
            title: "Fridge to Table",
            description:
                "FridgetoTable is a web app that allows users to manage their food inventory, and get recipe suggestions using <b>Edamam API</b> as well as the list of restaurants nearby using <b>Google Maps API</b>. Added Google <b>OAuth2</b>. Used <b>Redis</b> for caching session and API results.",
            languages: [
                "NodeJS",
                "Passport.js",
                "PostgreSQL",
                "Edamam API",
                "Google Maps API",
                "EJS",
                "Redis",
            ],
            liveLink: "https://fridgetotable.co",
            githubLink: "https://github.com/anshg1214/fridgetotable",
        },
    ],
};

const getInTouch = {
    show: true,
    heading: "Get In Touch",
    message:
        "If you have any questions, or if you just want to say hi, please feel free to email me at ",
    email: "hi@anshgoyal.com",
};

const educationData = {
    show: true,
    heading: "Education",
    data: [
        {
            id: 1,
            school: "BITS Pilani",
            degree: "B.E Computer Science and Engineering",
            date: "2019",
        },
        {
            id: 2,
            school: "Delhi Public School, Mathura Road, New Delhi",
            degree: "AISSCE",
            date: "2014",
        },
    ],
};

const skillsData = {
    show: true,
    heading: "Skills",
    message:
        "During the course of my journey, I've built a solid foundation in computer science and web development. I am familiar with the following languages",
    skills: [
        {
            id: 1,
            name: "NodeJS",
            image: "fab fa-node",
        },
        {
            id: 2,
            name: "ExpressJS",
            image: "",
            imagealt: require("../components/assets/icons/expressjs.svg")
                .default,
        },
        {
            id: 3,
            name: "React",
            image: "fab fa-react",
        },
        {
            id: 4,
            name: "JavaScript",
            image: "fab fa-js",
        },
        {
            id: 5,
            name: "MongoDB",
            image: "",
            imagealt: require("../components/assets/icons/mongodb.svg").default,
        },
        {
            id: 6,
            name: "PostgreSQL",
            image: "",
            imagealt: require("../components/assets/icons/postgresql.svg")
                .default,
        },
        {
            id: 7,
            name: "Flask",
            image: "",
            imagealt: require("../components/assets/icons/flask-icon.svg")
                .default,
        },
        {
            id: 8,
            name: "Python",
            image: "fab fa-python",
        },
        {
            id: 9,
            name: "C++",
            image: "",
            imagealt: require("../components/assets/icons/cpp.svg").default,
        },
        {
            id: 10,
            name: "HTML",
            image: "fab fa-html5",
        },
        {
            id: 11,
            name: "CSS",
            image: "fab fa-css3-alt",
        },
    ],
};

export { mainBody, about, projectData, getInTouch, educationData, skillsData };
