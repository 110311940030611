import React, { useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

const Navigation = () => {
    const [isTop, setisTop] = useState(true);
    const handleScroll = () => {
        const position = window.pageYOffset;
        if (position > 200) {
            setisTop(false);
        } else {
            setisTop(true);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <Navbar
            className={` fixed-top  ${
                !isTop ? "navbar-white" : "navbar-transparent"
            }`}
            expand="lg"
        >
            <Navbar.Brand
                className={` brand ${isTop ? "text-white" : "text-brand"}`}
                href={process.env.PUBLIC_URL + "/#"}
            >
                {" < Ansh Goyal />"}
            </Navbar.Brand>
            <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                className="toggler"
            />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link
                        className={` nav-link lead ${
                            isTop ? "text-white" : null
                        }`}
                        href={process.env.PUBLIC_URL + "/#about"}
                    >
                        About
                    </Nav.Link>
                    <Nav.Link
                        className={` nav-link lead   ${
                            isTop ? "text-white" : null
                        }`}
                        href={process.env.PUBLIC_URL + "/#projects"}
                    >
                        Projects
                    </Nav.Link>
                    <Nav.Link
                        className={` nav-link lead   ${
                            isTop ? "text-white" : null
                        }`}
                        href={process.env.PUBLIC_URL + "/#skills"}
                    >
                        Skills
                    </Nav.Link>
                    <Nav.Link
                        className={` nav-link lead   ${
                            isTop ? "text-white" : null
                        }`}
                        href={process.env.PUBLIC_URL + "/#contact"}
                    >
                        Contact
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Navigation;
