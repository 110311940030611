import React from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Button, Badge } from "react-bootstrap";
import parse from "html-react-parser";

const ProjectCard = (props) => {
    const { title, description, liveLink, githubLink, languages } = props.data;

    const buttonLive = () => {
        if (liveLink) {
            return (
                <Button
                    variant="outline-dark mr-4"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={liveLink}
                >
                    <i className="fas fa-location-arrow"></i> Live
                </Button>
            );
        }
    };

    return (
        <Col md={6}>
            <Card className="card shadow-lg p-3 mb-5 bg-white rounded">
                <Card.Body>
                    <Card.Title>{title}</Card.Title>
                    <Card.Text>{parse(description)}</Card.Text>

                    {buttonLive()}
                    <Button
                        variant="outline-dark"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={githubLink}
                    >
                        <i className="fab fa-github" /> Repo
                    </Button>
                    <hr />
                    <div className="pb-3">
                        Built With:{" "}
                        {languages.map((language) => (
                            <span
                                key={language}
                                className="badge badge-dark mr-1"
                            >
                                {language}
                            </span>
                        ))}
                    </div>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default ProjectCard;
