import React from "react";

import profilepic from "../../information/profilepic.webp";

const AboutMe = (props) => {
    const { imageLink, heading, message, imageSize } = props.data;
    const width = window.innerWidth;
    const widthShow = () => {
        if (width > 1000) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div id="about" className="jumbotron jumbotron-fluid m-0">
            <div className="container container-fluid">
                <div className="row">
                    <div className="col-5 d-none d-lg-block align-self-center lefttt">
                        <img
                            className="border border-secondary rounded-circle"
                            src={profilepic}
                            width={imageSize}
                            height={imageSize}
                            alt="profilepic"
                        />
                        <div className="after"></div>
                    </div>

                    <div className={`col-lg-${widthShow ? "7" : "12"}`}>
                        <h2 className="display-4 mb-5 text-center">
                            {heading}
                        </h2>
                        <p className="lead text-center">{message}</p>
                        {/* <div className="lead text-center">
                            <button
                                type="button"
                                className="btn btn-outline-dark btn-lg mt-4"
                            >
                                Resume
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutMe;
