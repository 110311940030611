import React from "react";
import ProjectCard from "./ProjectCard";

const Project = (props) => {
    const { heading, projects } = props.data;

    return (
        <div id="projects" className="jumbotron jumbotron-fluid m-0 bg-light">
            <div className="container">
                <h2 className="display-4 mb-5 text-center">{heading}</h2>
                <div className="row">
                    {projects.map((project) => (
                        <ProjectCard key={project.id} data={project} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Project;
