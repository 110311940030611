import React from "react";

const MainBody = (props) => {
    const { message, icons } = props.data;
    return (
        <div
            id="mainbody"
            className="title bg-transparent bgstyle text-light min-vh-100 d-flex align-content-center align-items-center flex-wrap m-0 jumbotron jumbotron-fluid"
        >
            <div className="stars"></div>
            <div className="stars2"></div>
            <div className="stars3"></div>
            <div className="text-center container">
                <h1 className="display-1">Ansh Goyal</h1>
                <p className="lead">{message}</p>
                <div className="p-5">
                    {icons.map((icon, index) => (
                        <a
                            key={`social-icon-${index}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={icon.url}
                            aria-label={`My ${icon.image.split("-")[1]}`}
                        >
                            <i className={`${icon.image}  fa-3x socialicons`} />
                        </a>
                    ))}
                </div>
                <div className="arrowdown">
                    <a
                        href={process.env.PUBLIC_URL + "/#about"}
                        aria-label="down"
                    >
                        <i className="fas fa-chevron-down fa-5x text-white"></i>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default MainBody;
