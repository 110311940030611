import React from "react";
import SkillsCard from "./SkillsCard";

const Skills = (props) => {
    const { heading, message, skills } = props.data;
    return (
        <div id="skills" className="jumbotron jumbotron-fluid m-0 bg-white">
            <div className="container">
                <h2 className="display-4 mb-5 text-center">{heading}</h2>
                <p className="lead text-center">{message}</p>
                <div className="row d-flex justify-content-center">
                    {skills.map((skill, index) => (
                        <SkillsCard key={index} data={skill} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Skills;
