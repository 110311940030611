import React from "react";
import {
    mainBody,
    about,
    educationData,
    skillsData,
    projectData,
    getInTouch,
} from "./information/config.js";

import Navigation from "./components/Navbar.jsx";
import MainBody from "./components/home/MainBody.jsx";
import AboutMe from "./components/home/AboutMe.jsx";
// import Education from "./components/home/Education.jsx";
import Skills from "./components/home/Skills.jsx";
import Project from "./components/home/Project.jsx";
import GetInTouch from "./components/home/GetInTouch.jsx";
import Footer from "./components/Footer.jsx";

import "bootstrap/dist/css/bootstrap.min.css";
import "./css/main.css";
import "./css/gradient.css";
import "./css/stars.css";

const App = () => {
    return (
        <div className="App">
            <Navigation />
            <MainBody data={mainBody} />
            <AboutMe data={about} />
            <Project data={projectData} />
            <Skills data={skillsData} />
            {/* <Education data={educationData} /> */}
            <Footer>
                <GetInTouch data={getInTouch} />
            </Footer>
        </div>
    );
};

export default App;
