import React from "react";
import Container from "react-bootstrap/Container";

const Footer = (props) => {
    return (
        <footer
            className="mt-auto py-5 text-center"
            id="contact"
            style={{ backgroundColor: "#f5f5f5" }}
        >
            <Container>
                {props.children}
                <i className="fas fa-code" /> with{" "}
                <i className="fas fa-heart" /> by{" "}
                <a
                    className="badge badge-dark"
                    rel="noopener"
                    href="https://github.com/anshg1214"
                    aria-label="My GitHub"
                >
                    Ansh Goyal
                </a>{" "}
                using <i className="fab fa-react" />
            </Container>
        </footer>
    );
};

export default Footer;
